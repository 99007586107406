
import { Vue, Options } from "vue-class-component";
import ClaimDetailCard from "@/app/ui/views/dashboard/component/client/section-claim/claim-detail-card.vue";
import ClaimRejectedReasonCard from "@/app/ui/views/dashboard/component/client/section-claim/claim-rejected-reason-card.vue";

@Options({
  components: {
    ClaimRejectedReasonCard,
    ClaimDetailCard
  }
})
export default class SectionClaimDashboard extends Vue {
  checkActiveSectionDashboard(section: string) {
    const configCorporateDashboard =
      process.env.VUE_APP_CONFIG_CORPORATE_DASHBOARD;
    if (!configCorporateDashboard) return false;
    return configCorporateDashboard.includes(section);
  }
}
