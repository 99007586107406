import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-3 grid grid-cols-2 gap-3" }
const _hoisted_2 = { class: "text-12px text-black-lp-300 font-normal whitespace-nowrap overflow-hidden overflow-ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_CardDashboardClient = _resolveComponent("CardDashboardClient")!

  return (_openBlock(), _createBlock(_component_CardDashboardClient, {
    loading: _ctx.isLoading,
    title: "Alasan Ditolak"
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.claimReasons, (rejectedReason, index) => {
          return (_openBlock(), _createBlock(_Fragment, { key: index }, [
            _createVNode("div", _hoisted_2, _toDisplayString(rejectedReason.reason), 1),
            _createVNode(_component_ProgressBar, {
              value: _ctx.valueProgressBar(rejectedReason.count, _ctx.maxValueClaimReasons),
              realValue: rejectedReason.count,
              class: "w-full mr-2",
              startValue: 0,
              endValue: _ctx.maxValueClaimReasons,
              customColor: _ctx.claimReasonsColor[index],
              multipleBar: ""
            }, null, 8, ["value", "realValue", "endValue", "customColor"])
          ], 64))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}