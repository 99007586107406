
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import ProgressBar from "@/app/ui/components/progress-bar/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { ClaimReason } from "@/domain/entities/Dashboard";

@Options({
  components: {
    CardDashboardClient,
    ProgressBar
  }
})
export default class ClaimRejectedReasonCard extends Vue {
  mounted() {
    this.fetchClaimReason();
  }

  isLoading = true;
  claimReasons: ClaimReason[] = [];
  claimReasonsColor: Array<string> = [
    "#51B6D5",
    "#F05C72",
    "#36B37E",
    "#8E78DB",
    "#FAB713"
  ];
  get maxValueClaimReasons() {
    return this.claimReasons.reduce((a, b) => {
      return a + b.count;
    }, 0);
  }
  async fetchClaimReason() {
    this.isLoading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/claim/reasons",
        sttCategory: "",
        cache: true
      }
    );
    this.claimReasons = response.claimReasons.slice(0, 5);
    this.isLoading = false;
  }
  valueProgressBar(val: number, maxValue: number) {
    return Math.floor((val / maxValue) * 100);
  }
}
